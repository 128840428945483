@media screen and (max-width: 960px) {
  .channel-list-wrapper {
    visibility: hidden;
  }

  .channel-list-wrapper--active {
    visibility: visible;
  }

  .channel-list-wrapper--active > .str-chat-channel-list {
    position: fixed;
    left: 0 !important;
    height: 100vh;
    overflow-y: auto;
    box-shadow: 7px 0 9px 0 #0000000d, 1px 0 0 0 #0000000d;
    transition: left 200ms ease-in-out;
    float: left;
    box-shadow: 7px 0 9px 0 var(--black5), 1px 0 0 0 var(--black5);
    z-index: 9999 !important;
  }
}

.str-chat,
.str-chat__loading-channels,
.str-chat__channel-list-messenger,
.str-chat__textarea textarea {
  background: #f3f3f3 !important;
}

.str-chat__main-panel {
  padding: 0 !important;
}

.channel-list-wrapper--active {
  margin: 8px 0;
  width: 100%;
}

.str-chat__down-main {
  width: calc(100vw - 15px);
}

.str-chat {
  height: calc(100vh - 150px) !important;
}
